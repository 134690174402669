import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Gallery from '@browniebroke/gatsby-image-gallery'

const ArtPage = ({ data }) => {

	const watercolors = data.watercolors.edges.map(({ node }, index) => ({
	  ...node.childImageSharp,
	}));

	const oils = data.oils.edges.map(({ node }, index) => ({
		...node.childImageSharp,
	}));

	const acrylics = data.acrylics.edges.map(({ node }, index) => ({
	...node.childImageSharp,
	}));

	const temperas = data.temperas.edges.map(({ node }, index) => ({
	...node.childImageSharp,
	}));
  
	return (
		<Layout pageTitle="Art Page">
			<Section id="more-art" background="light">
				<div>
					<h2>More paintings</h2>
					<p>Here are some examples of Richard’s art. See more at <a target="_blank" rel="noreferrer" href="http://pbase.com/jacamar/new_paintings">pbase.com/jacamar/new_paintings</a>. To inquire about purchasing a painting, e-mail Richard: r.weeks@comcast.net</p>
				</div>
				<div>
					<h3>Watercolors</h3>
					<Gallery
						images={ watercolors }
					/>
				</div>
				<div>
					<h3>Oil paintings</h3>
					<Gallery
						images={ oils }
					/>
				</div>
				<div>
					<h3>Acrylic paintings</h3>
					<Gallery
						images={ acrylics }
					/>
				</div>
				<div>
					<h3>Egg tempera</h3>
					<Gallery
						images={ temperas }
					/>
				</div>
			</Section>
	    </Layout>
	)
}

export const query = graphql`
	query ImagesForGallery {
		oils: allFile(
			filter: { relativeDirectory: { eq: "gallery/oil" } }
			sort: { fields: name }
		) {
			edges {
				node {
					childImageSharp {
						thumb: gatsbyImageData(
							width: 250
							height: 250
							placeholder: BLURRED
						)
						full: gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}
		acrylics: allFile(
			filter: { relativeDirectory: { eq: "gallery/acrylic" } }
			sort: { fields: name }
		) {
			edges {
				node {
					childImageSharp {
						thumb: gatsbyImageData(
							width: 250
							height: 250
							placeholder: BLURRED
						)
						full: gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}
		watercolors: allFile(
			filter: { relativeDirectory: { eq: "gallery/watercolor" } }
			sort: { fields: name }
		) {
			edges {
				node {
					childImageSharp {
						thumb: gatsbyImageData(
							width: 250
							height: 250
							placeholder: BLURRED
						)
						full: gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}
		temperas: allFile(
			filter: { relativeDirectory: { eq: "gallery/tempera" } }
			sort: { fields: name }
		) {
			edges {
				node {
					childImageSharp {
						thumb: gatsbyImageData(
							width: 250
							height: 250
							placeholder: BLURRED
						)
						full: gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`

export default ArtPage